import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const contact = () => {
  return (
    <Layout>
      <SEO
        title="Contract and Stamina Renewal"
        description="Learn how to renew your players or managers contracts in PES myClub, as well as how to replenish players' stamina after matches."
      />
      <div className="container">
        <h1>Contract and Stamina Renewal</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Contract and Stamina Renewal</li>
        </ul>
        <p>
          Contract renewals come with a high cost in PES 2021, and the more you
          play, the more you will notice the impact they can have on your club
          balance.
        </p>
        <p>
          This is something that usually surprises newcomers in PES myClub.
          Getting the players or managers isn’t the hard part - using them in
          every game is the real test.
        </p>
        <p>
          Managing your players' Stamina is also challenging since you can't buy
          Stamina Recovery Items, and Stamina is affected after each completed
          match.
        </p>
        <p>Here are the best ways to manage all these problems.</p>
        <h2>Player Contract Renewal</h2>
        <p>
          Every 10 games, you will need to renew a player’s contract. A Black
          Ball player is more expensive than a Silver Ball, costing around 3-4k
          GP. This will be a problem, as this is roughly the same GP you make
          when you win a game, and if you’re rocking a 5-star team, you will
          need about 35k every 10 games to keep using all your players.
        </p>
        <p>
          You can use coins to renew your players’ contracts, but I wouldn’t
          recommend it - you should save them for the weekly Featured Players or
          top Managers, especially if you’re just getting started.
        </p>
        <p>
          If you don’t renew the contract right away, you will still keep the
          player in your club - you simply won’t be able to play with him until
          the contract is renewed.
        </p>
        <p>
          Every now and then you will get some “Contract Renewal Tickets”. This
          basically means you can renew any player contract without spending
          GP/coins. Naturally, you should save those Tickets for your most
          expensive players.
        </p>
        <p>
          Players' contract renewal become more expensive as they level up. If
          you're scouting for a player like Ronaldo, who probably will play
          often in your team, you can save some GP by immediately renewing his
          contract for dozens or hundreds of games while he is still level 1.
        </p>
        <p>
          As you can see, this is can be problematic if you play every game with
          your Black Ball players. So what can you do?
        </p>
        <p>
          Some PES players prefer to pick some Gold, Silver, or Bronze players
          and train them so they get more competitive (
          <Link to="/best-players-ball-type/">
            have a look at some of my favorites
          </Link>
          ), while others simply avoid playing 5-star matches (usually playing
          3-star matches instead).
        </p>
        <p>
          In the end, it all comes down to having GP in your club so that you
          can renew any contract at any given time. Make sure you follow{" "}
          <Link to="/how-to-get-more-gp/">
            these tips to get more GP in PES myClub
          </Link>
          .
        </p>
        <h2>Manager Contract Renewal</h2>
        <p>
          Managers are a little different: when their contract runs out,
          provided you have fulfilled their objectives, they will be extended
          for free.
        </p>
        <p>
          If you failed to achieve their goals, you will need to spend GP/coins
          to renew their contracts - top managers are expensive (7k or more) but
          at least their contracts are renewed for 25 games.
        </p>
        <p>
          Objectives vary depending on the coach you are using and how
          successful you’ve been in the previous run. At the end of each game
          you will get a status update on your objectives.
        </p>
        <p>
          To renew their contracts for free, you need to accomplish a certain
          amount of points in one of the following competitions (period of 25
          games):
        </p>
        <ul>
          <li>Ranked Match / Online Challenge Cup</li>
          <li>Ranked Match (SIM)</li>
          <li>VS COM/VS COM Challenge Cup</li>
        </ul>
        <p>
          In my experience, SIMs are usually the least demanding, but you can’t
          really control the outcome of the games. VS COM games are probably the
          safest bet provided you’re a decent player, but you will have to play
          a lot of those to secure a free contract renewal.
        </p>
        <h2>Player Stamina Renewal</h2>
        <p>
          Your players will lose a bit of stamina after every game (the only
          exception is if you're playing myClub friendly matches). You don’t
          need to refill it all the time, but that’s something you should keep
          in mind after 3 games or so, in order to stay competitive.
        </p>
        <p>
          You can rest your players and switch to a different team, so that
          their stamina gradually recovers in the background. You can also
          switch to your SIM team and run a couple of SIM matches to rest your
          superstars.
        </p>
        <p>
          If you need a quick fix, you can use a Stamina Recovery Item to get a
          player back to 100%. To use them, you will need to go to the CLUB
          HOUSE / SQUAD MANAGEMENT screen, pick the player you want and then
          press the "Player Menu" button. You will see a "Stamina Recovery
          (single)" item.
        </p>
        <p>
          You can also replenish your whole team's stamina, although that's
          rarely a good use of Items. To do so, instead of picking a player,
          press the "Team Menu" button and use the "Stamina Recovery (All)"
          option.
        </p>
        <p>
          These items are awarded to you as daily login rewards or after
          completing a certain number of games/actions. You get 12 Stamina
          Recovery Item each time.
        </p>
      </div>
    </Layout>
  )
}

export default contact
